import '@angular/compiler';
import { PortalKrdcAppUsageComponent } from './portal.krdc.app.usage/portal.krdc.app.usage.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalDizestEditorImageComponent } from './portal.dizest.editor.image/portal.dizest.editor.image.component';
import { PageDetailComponent } from './page.detail/page.detail.component';
import { PortalDizestSidebarCodeflowComponent } from './portal.dizest.sidebar.codeflow/portal.dizest.sidebar.codeflow.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PageMainComponent } from './page.main/page.main.component';
import { ComponentAsideComponent } from './component.aside/component.aside.component';
import { PortalDizestEditorNotsupportedComponent } from './portal.dizest.editor.notsupported/portal.dizest.editor.notsupported.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalKrdcAdminMetadataComponent } from './portal.krdc.admin.metadata/portal.krdc.admin.metadata.component';
import { PortalKrdcAppListComponent } from './portal.krdc.app.list/portal.krdc.app.list.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalKrdcWorkflowBookmarkComponent } from './portal.krdc.workflow.bookmark/portal.krdc.workflow.bookmark.component';
import { PortalDizestSidebarPackagesComponent } from './portal.dizest.sidebar.packages/portal.dizest.sidebar.packages.component';
import { PageServiceEditComponent } from './page.service.edit/page.service.edit.component';
import { ComponentFooterComponent } from './component.footer/component.footer.component';
import { PortalDizestCodeflowAppinfoComponent } from './portal.dizest.codeflow.appinfo/portal.dizest.codeflow.appinfo.component';
import { PortalDizestEditorTerminalComponent } from './portal.dizest.editor.terminal/portal.dizest.editor.terminal.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PageAppComponent } from './page.app/page.app.component';
import { PortalDizestDriveComponent } from './portal.dizest.drive/portal.dizest.drive.component';
import { PortalKrdcAdminTemplateComponent } from './portal.krdc.admin.template/portal.krdc.admin.template.component';
import { PortalDizestEditorSettingComponent } from './portal.dizest.editor.setting/portal.dizest.editor.setting.component';
import { PortalKrdcAdminTemplateEditComponent } from './portal.krdc.admin.template.edit/portal.krdc.admin.template.edit.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalKrdcAppDevelopComponent } from './portal.krdc.app.develop/portal.krdc.app.develop.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { ComponentDatePickerComponent } from './component.date.picker/component.date.picker.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { PortalKrdcServiceDevelopComponent } from './portal.krdc.service.develop/portal.krdc.service.develop.component';
import { PageEditComponent } from './page.edit/page.edit.component';
import { PortalKrdcAdminWorkflowsComponent } from './portal.krdc.admin.workflows/portal.krdc.admin.workflows.component';
import { ComponentLoadingComponent } from './component.loading/component.loading.component';
import { ComponentStepComponent } from './component.step/component.step.component';
import { LayoutUserComponent } from './layout.user/layout.user.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PortalDizestStatuscardComponent } from './portal.dizest.statuscard/portal.dizest.statuscard.component';
import { PortalDizestSidebarAppinfoComponent } from './portal.dizest.sidebar.appinfo/portal.dizest.sidebar.appinfo.component';
import { PortalKrdcServiceListComponent } from './portal.krdc.service.list/portal.krdc.service.list.component';
import { PortalDizestEditorUimodeComponent } from './portal.dizest.editor.uimode/portal.dizest.editor.uimode.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalDizestEditorTextComponent } from './portal.dizest.editor.text/portal.dizest.editor.text.component';
import { PageSignupComponent } from './page.signup/page.signup.component';
import { PortalKrdcWorkflowComponent } from './portal.krdc.workflow/portal.krdc.workflow.component';
import { PortalKrdcAdminApproveComponent } from './portal.krdc.admin.approve/portal.krdc.admin.approve.component';
import { PortalKrdcAdminTotalComponent } from './portal.krdc.admin.total/portal.krdc.admin.total.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { PageSearchComponent } from './page.search/page.search.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalDizestModalCreateWorkflowComponent } from './portal.dizest.modal.create.workflow/portal.dizest.modal.create.workflow.component';
import { PortalKrdcWorkflowUsageComponent } from './portal.krdc.workflow.usage/portal.krdc.workflow.usage.component';
import { PortalDizestEditorVideoComponent } from './portal.dizest.editor.video/portal.dizest.editor.video.component';
import { PortalKrdcAdminPublishComponent } from './portal.krdc.admin.publish/portal.krdc.admin.publish.component';
import { PortalDizestCodeflowNoteComponent } from './portal.dizest.codeflow.note/portal.dizest.codeflow.note.component';
import { PortalDizestSidebarWorkflowinfoComponent } from './portal.dizest.sidebar.workflowinfo/portal.dizest.sidebar.workflowinfo.component';
import { PortalDizestEditorNewComponent } from './portal.dizest.editor.new/portal.dizest.editor.new.component';
import { PortalDizestHealthComponent } from './portal.dizest.health/portal.dizest.health.component';
import { ComponentNavUserComponent } from './component.nav.user/component.nav.user.component';
import { PortalKrdcAppBookmarkComponent } from './portal.krdc.app.bookmark/portal.krdc.app.bookmark.component';
import { PageServiceComponent } from './page.service/page.service.component';
import { PortalDizestEditorWorkflowComponent } from './portal.dizest.editor.workflow/portal.dizest.editor.workflow.component';
import { PortalKrdcAdminUserComponent } from './portal.krdc.admin.user/portal.krdc.admin.user.component';
import { ComponentQuestionComponent } from './component.question/component.question.component';
import { PortalDizestAppsComponent } from './portal.dizest.apps/portal.dizest.apps.component';
import { PageWorkflowComponent } from './page.workflow/page.workflow.component';
import { PortalKrdcLicenseInfoComponent } from './portal.krdc.license.info/portal.krdc.license.info.component';
import { PortalKrdcAdminListComponent } from './portal.krdc.admin.list/portal.krdc.admin.list.component';
import { PortalDizestSidebarTimerComponent } from './portal.dizest.sidebar.timer/portal.dizest.sidebar.timer.component';
import { PortalDizestMenuWorkflowsComponent } from './portal.dizest.menu.workflows/portal.dizest.menu.workflows.component';
import { PortalDizestWidgetWorkflowNodeComponent } from './portal.dizest.widget.workflow.node/portal.dizest.widget.workflow.node.component';
import { PortalKrdcSearchFilterComponent } from './portal.krdc.search.filter/portal.krdc.search.filter.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

@NgModule({
    declarations: [
        AppComponent,
        PortalKrdcAppUsageComponent,
        PortalSeasonLoadingComponent,
        PortalDizestEditorImageComponent,
        PageDetailComponent,
        PortalDizestSidebarCodeflowComponent,
        PortalSeasonPagenationComponent,
        PageMainComponent,
        ComponentAsideComponent,
        PortalDizestEditorNotsupportedComponent,
        PortalSeasonStatusbarComponent,
        PortalKrdcAdminMetadataComponent,
        PortalKrdcAppListComponent,
        PortalSeasonLoadingHexaComponent,
        PortalSeasonUiDropdownComponent,
        PortalKrdcWorkflowBookmarkComponent,
        PortalDizestSidebarPackagesComponent,
        PageServiceEditComponent,
        ComponentFooterComponent,
        PortalDizestCodeflowAppinfoComponent,
        PortalDizestEditorTerminalComponent,
        PortalSeasonLoadingSeasonComponent,
        PageAppComponent,
        PortalDizestDriveComponent,
        PortalKrdcAdminTemplateComponent,
        PortalDizestEditorSettingComponent,
        PortalKrdcAdminTemplateEditComponent,
        PortalSeasonTabComponent,
        PortalKrdcAppDevelopComponent,
        LayoutEmptyComponent,
        ComponentDatePickerComponent,
        LayoutAdminComponent,
        PortalKrdcServiceDevelopComponent,
        PageEditComponent,
        PortalKrdcAdminWorkflowsComponent,
        ComponentLoadingComponent,
        ComponentStepComponent,
        LayoutUserComponent,
        PageMypageComponent,
        PortalDizestStatuscardComponent,
        PortalDizestSidebarAppinfoComponent,
        PortalKrdcServiceListComponent,
        PortalDizestEditorUimodeComponent,
        PortalSeasonAlertComponent,
        PortalDizestEditorTextComponent,
        PageSignupComponent,
        PortalKrdcWorkflowComponent,
        PortalKrdcAdminApproveComponent,
        PortalKrdcAdminTotalComponent,
        PageAdminComponent,
        PageSearchComponent,
        PortalSeasonViewerTreeComponent,
        PortalDizestModalCreateWorkflowComponent,
        PortalKrdcWorkflowUsageComponent,
        PortalDizestEditorVideoComponent,
        PortalKrdcAdminPublishComponent,
        PortalDizestCodeflowNoteComponent,
        PortalDizestSidebarWorkflowinfoComponent,
        PortalDizestEditorNewComponent,
        PortalDizestHealthComponent,
        ComponentNavUserComponent,
        PortalKrdcAppBookmarkComponent,
        PageServiceComponent,
        PortalDizestEditorWorkflowComponent,
        PortalKrdcAdminUserComponent,
        ComponentQuestionComponent,
        PortalDizestAppsComponent,
        PageWorkflowComponent,
        PortalKrdcLicenseInfoComponent,
        PortalKrdcAdminListComponent,
        PortalDizestSidebarTimerComponent,
        PortalDizestMenuWorkflowsComponent,
        PortalDizestWidgetWorkflowNodeComponent,
        PortalKrdcSearchFilterComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        KeyboardShortcutsModule.forRoot(),
        NuMonacoEditorModule.forRoot({ baseUrl: `lib` }),
        
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'ko-KR'},
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }