import '@angular/compiler';
import { PortalKrdcAppUsageComponent } from './portal.krdc.app.usage/portal.krdc.app.usage.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalDizestEditorImageComponent } from './portal.dizest.editor.image/portal.dizest.editor.image.component';
import { PageDetailComponent } from './page.detail/page.detail.component';
import { PortalDizestSidebarCodeflowComponent } from './portal.dizest.sidebar.codeflow/portal.dizest.sidebar.codeflow.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PageMainComponent } from './page.main/page.main.component';
import { ComponentAsideComponent } from './component.aside/component.aside.component';
import { PortalDizestEditorNotsupportedComponent } from './portal.dizest.editor.notsupported/portal.dizest.editor.notsupported.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalKrdcAdminMetadataComponent } from './portal.krdc.admin.metadata/portal.krdc.admin.metadata.component';
import { PortalKrdcAppListComponent } from './portal.krdc.app.list/portal.krdc.app.list.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalKrdcWorkflowBookmarkComponent } from './portal.krdc.workflow.bookmark/portal.krdc.workflow.bookmark.component';
import { PortalDizestSidebarPackagesComponent } from './portal.dizest.sidebar.packages/portal.dizest.sidebar.packages.component';
import { PageServiceEditComponent } from './page.service.edit/page.service.edit.component';
import { ComponentFooterComponent } from './component.footer/component.footer.component';
import { PortalDizestCodeflowAppinfoComponent } from './portal.dizest.codeflow.appinfo/portal.dizest.codeflow.appinfo.component';
import { PortalDizestEditorTerminalComponent } from './portal.dizest.editor.terminal/portal.dizest.editor.terminal.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PageAppComponent } from './page.app/page.app.component';
import { PortalDizestDriveComponent } from './portal.dizest.drive/portal.dizest.drive.component';
import { PortalKrdcAdminTemplateComponent } from './portal.krdc.admin.template/portal.krdc.admin.template.component';
import { PortalDizestEditorSettingComponent } from './portal.dizest.editor.setting/portal.dizest.editor.setting.component';
import { PortalKrdcAdminTemplateEditComponent } from './portal.krdc.admin.template.edit/portal.krdc.admin.template.edit.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalKrdcAppDevelopComponent } from './portal.krdc.app.develop/portal.krdc.app.develop.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { ComponentDatePickerComponent } from './component.date.picker/component.date.picker.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { PortalKrdcServiceDevelopComponent } from './portal.krdc.service.develop/portal.krdc.service.develop.component';
import { PageEditComponent } from './page.edit/page.edit.component';
import { PortalKrdcAdminWorkflowsComponent } from './portal.krdc.admin.workflows/portal.krdc.admin.workflows.component';
import { ComponentLoadingComponent } from './component.loading/component.loading.component';
import { ComponentStepComponent } from './component.step/component.step.component';
import { LayoutUserComponent } from './layout.user/layout.user.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PortalDizestStatuscardComponent } from './portal.dizest.statuscard/portal.dizest.statuscard.component';
import { PortalDizestSidebarAppinfoComponent } from './portal.dizest.sidebar.appinfo/portal.dizest.sidebar.appinfo.component';
import { PortalKrdcServiceListComponent } from './portal.krdc.service.list/portal.krdc.service.list.component';
import { PortalDizestEditorUimodeComponent } from './portal.dizest.editor.uimode/portal.dizest.editor.uimode.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalDizestEditorTextComponent } from './portal.dizest.editor.text/portal.dizest.editor.text.component';
import { PageSignupComponent } from './page.signup/page.signup.component';
import { PortalKrdcWorkflowComponent } from './portal.krdc.workflow/portal.krdc.workflow.component';
import { PortalKrdcAdminApproveComponent } from './portal.krdc.admin.approve/portal.krdc.admin.approve.component';
import { PortalKrdcAdminTotalComponent } from './portal.krdc.admin.total/portal.krdc.admin.total.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { PageSearchComponent } from './page.search/page.search.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalDizestModalCreateWorkflowComponent } from './portal.dizest.modal.create.workflow/portal.dizest.modal.create.workflow.component';
import { PortalKrdcWorkflowUsageComponent } from './portal.krdc.workflow.usage/portal.krdc.workflow.usage.component';
import { PortalDizestEditorVideoComponent } from './portal.dizest.editor.video/portal.dizest.editor.video.component';
import { PortalKrdcAdminPublishComponent } from './portal.krdc.admin.publish/portal.krdc.admin.publish.component';
import { PortalDizestCodeflowNoteComponent } from './portal.dizest.codeflow.note/portal.dizest.codeflow.note.component';
import { PortalDizestSidebarWorkflowinfoComponent } from './portal.dizest.sidebar.workflowinfo/portal.dizest.sidebar.workflowinfo.component';
import { PortalDizestEditorNewComponent } from './portal.dizest.editor.new/portal.dizest.editor.new.component';
import { PortalDizestHealthComponent } from './portal.dizest.health/portal.dizest.health.component';
import { ComponentNavUserComponent } from './component.nav.user/component.nav.user.component';
import { PortalKrdcAppBookmarkComponent } from './portal.krdc.app.bookmark/portal.krdc.app.bookmark.component';
import { PageServiceComponent } from './page.service/page.service.component';
import { PortalDizestEditorWorkflowComponent } from './portal.dizest.editor.workflow/portal.dizest.editor.workflow.component';
import { PortalKrdcAdminUserComponent } from './portal.krdc.admin.user/portal.krdc.admin.user.component';
import { ComponentQuestionComponent } from './component.question/component.question.component';
import { PortalDizestAppsComponent } from './portal.dizest.apps/portal.dizest.apps.component';
import { PageWorkflowComponent } from './page.workflow/page.workflow.component';
import { PortalKrdcLicenseInfoComponent } from './portal.krdc.license.info/portal.krdc.license.info.component';
import { PortalKrdcAdminListComponent } from './portal.krdc.admin.list/portal.krdc.admin.list.component';
import { PortalDizestSidebarTimerComponent } from './portal.dizest.sidebar.timer/portal.dizest.sidebar.timer.component';
import { PortalDizestMenuWorkflowsComponent } from './portal.dizest.menu.workflows/portal.dizest.menu.workflows.component';
import { PortalDizestWidgetWorkflowNodeComponent } from './portal.dizest.widget.workflow.node/portal.dizest.widget.workflow.node.component';
import { PortalKrdcSearchFilterComponent } from './portal.krdc.search.filter/portal.krdc.search.filter.component';

const INDEX_PAGE = "main";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutUserComponent,
        "children": [
            {
                "path": "detail/:type/:id",
                component: PageDetailComponent,
                "app_id": "page.detail"
            },
            {
                "path": "main",
                component: PageMainComponent,
                "app_id": "page.main"
            },
            {
                "path": "service/edit/:id",
                component: PageServiceEditComponent,
                "app_id": "page.service.edit"
            },
            {
                "path": "app/:category?",
                component: PageAppComponent,
                "app_id": "page.app"
            },
            {
                "path": "app/edit/:id",
                component: PageEditComponent,
                "app_id": "page.edit"
            },
            {
                "path": "mypage",
                component: PageMypageComponent,
                "app_id": "page.mypage"
            },
            {
                "path": "signup",
                component: PageSignupComponent,
                "app_id": "page.signup"
            },
            {
                "path": "search",
                component: PageSearchComponent,
                "app_id": "page.search"
            },
            {
                "path": "service/:category?",
                component: PageServiceComponent,
                "app_id": "page.service"
            },
            {
                "path": "workflow/:category?",
                component: PageWorkflowComponent,
                "app_id": "page.workflow"
            }
        ]
    },
    {
        component: LayoutAdminComponent,
        "children": [
            {
                "path": "admin/:category?/:tab?",
                component: PageAdminComponent,
                "app_id": "page.admin"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }